ng-atomic-notify {
    position: fixed;
    right: 0;
}

.atomic-notify {
    z-index: 100000;
    right: 0;
    float: left;
    width: 500px;
}

.atomic-notify .atomic-notify-item {
    display: table;
    position: relative;
    margin: 40px auto 0;
    width: 500px;
    background-color: #0074D9;
    color: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    padding: 0px;
}

.atomic-notify .atomic-notify-item .icon {
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    padding: 30px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
}

.atomic-notify .atomic-notify-item .icon > i {
    width: 20px;
    font-size: 20px;
}

.atomic-notify .atomic-notify-warning {
    background-color: #F39C12;
}

.atomic-notify .atomic-notify-error {
  background-color: #FF4136;
}

.atomic-notify .atomic-notify-success {
  background-color: #2ECC40;
}

.atomic-notify .atomic-notify-item .body {
    display: table-cell;
    vertical-align: middle;
    padding: 30px 20px 30px 10px;
}

.atomic-notify .atomic-notify-item .body > p {
    line-height: 1.5;
    color: #fff;
}

.atomic-notify .close {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: none;
    outline: none;
    font-size: 20px;
    right: 5px;
    top: 5px;
    cursor: pointer;
    padding: 1px 8px 3px 7px;
}

.atomic-notify .close:hover {
    background-color: rgba(0, 0, 0, 0.5);
}